<template>
  <div :class="config.lineShow?'lineclass':''" @click="getCaptcha">{{message}}</div>
</template>
<script>
import * as apiCommon from '@/api/common';
import captcha from '@/utils/captcha';
import valid from '@/utils/valid';

export default {
  props: {
    config: {
      type: Object,
    },
  },

  data() {
    return {
      captcha: null,

      countDown: 60,
      interval: null,
    };
  },

  computed: {
    message(){ console.log(this.interval); return this.interval ? this.countDown : this.config.title; },
  },

  methods: {
    async getCaptcha() {
      console.log(this.config)
      // let param = {},
         let config = this.config;
          // captcha = this.captcha;
      console.log(2,config.mobile)
      if(!valid.isMobile(config.mobile)) return this.$emit("propShow","请输入正确的手机号")

      if(this.interval) return ;

      this.getCaptchaInfo();

     
    },

    clearInterval() {
      clearInterval(this.interval);
      this.interval = null;
    },
      async timeter(){
        let param = {},
          config = this.config,
          captcha = this.captcha;
           this.countDown = 60;
      this.interval = setInterval(() => { this.countDown <= 0 ? this.clearInterval() : this.countDown-- }, 1000);

      param.mobile = config.mobile;
      // param.type = config.type;
      if(config.userinfo){
        param.scene = "userinfo"
      }
      if(config.sales){
        param.scene = "sales"
      }
      param.randstr = captcha.randstr;
      param.ticket = captcha.ticket;
      console.log(1)
      let res = await apiCommon.getSmsCode(param);

      this.captcha = null;
      if(!res || typeof res === 'string' || res.error) return this.$toast.fail(res ? res.error || res : '获取验证码失败!'), this.clearInterval();

      this.$emit("captchaPop")
      },
    async getCaptchaInfo() {
      console.log(1)
      let res = await captcha.getCaptcha();
      console.log(res,"res")
      if(res) this.captcha = res;
      if(res){
        this.timeter()
      }
    }
  },

  created() { },
};
</script>
<style scoped>
  .lineclass{
    font-size: 20px;
    border-bottom: 1px solid #DEB77A;
    height:30px;
    margin: 10px 0 0 10px;padding-bottom: 2px
  }
</style>