<template>
    <div class="contaners_share">
        <img style="width:100%;height:100%" src="../../../assets/backgroundicon.png"/>
        <div @click="mineMessage" class="messageTitle13">
                <img class="messageIcon" src="../../../assets/phone2.png" />
                <div>联系客服</div>
            </div>
        <div class="content_activate">
            <div class="activate_text">该流程为系统使用前的老用户激活,请使用自身购购账户后在进行操作。</div>
            <div class="activate">
                <div class="activate_list">
                    <div class="activate_title1">购购账号</div>
                    <input class="inputSubmit_ac" placeholder="请输入购购账号"  placeholder-class="plass" v-model="captchaConfig.mobile"/>  
                </div>
                <div class="activate_list">
                    <div class="activate_title1" style="letter-spacing:10px">验证码</div>
                    <input class="inputSubmit_ac"  placeholder="请输入验证码" placeholder-class="plass"  v-model="captchaConfig.code"/>  
                    <!-- <div class="activate_title getCode">获取验证码</div> -->
                    <captcha @captchaPop="captchaPop" @propShow="propShow" ref='registerCaptcha' :config="captchaConfig" class="captcha_btn codeBox s_bg_11" slot="button"></captcha>
                </div>
            </div>
            <div class="activate_text3">*请输入本人的购购的账户</div>
            <div class="activateBtn" @click="loginBtn">进入</div>
            
        </div>
         <!-- 提示框 -->
      <toast v-if="toastShow" :config="configData"></toast>
    </div>
</template>
<script>
import captcha from "@/components/captcha";
import * as apiCommon from "@/api/common";
import toast from "@/components/toast";
import { getCode_api} from "@/api/common";
import Cookies from "js-cookie";
// import wx from "weixin-js-sdk";
    export default{
        components: {
    captcha,
    toast
  },
  data() {
    return {
      value: "",
      codeData: "",
      captchaConfig: {
        title: "获取验证码",
        mobile: "",
        type: 6,
        code: "",
        imgToken: "",
        lineShow:true
      },
      configData: {
        title: ""
      },
      toastShow: false,
      queryList:''
    };
  },
  async created() {
    document.title = "身份激活";
    this.queryList = this.$route.query
    // 判断用户
    this.applyUser();
    // this.getUserInfo1()
    // this.getToken();
    // this.getCode()
  },
  queryString(url) {
    let arr = []; //存储参数的数组
    let res = {}; //存储最终JSON结果对象
    arr = url.split("?")[1].split("&");
    for (let i = 0, len = arr.length; i < len; i++) {
      //如果有等号，则执行赋值操作
      if (arr[i].indexOf("=") != -1) {
        let str = arr[i].split("="); //str=[a,1];
        res[str[0]] = str[1];
      } else {
        //没有等号，则赋予空值
        res[arr[i]] = "";
      }
    }
    res = JSON.stringify(res); //转化为JSON字符串
    return res;
  },
  methods: {
      mineMessage() {
      // wx.closeWindow()
      this.$router.push("../../lador/service");
    },
    account() {
      window.location.href = "http://qr02.cn/B0Npsm";
    },
    // 提示框
    captchaPop() {
      this.toastShow = true;
      this.configData.title = "发送成功";
      setTimeout(() => {
        this.toastShow = false;
      }, 1000);
    },
    getCode() {
      //let redirect_uri = encodeURIComponent(location.href);
      let redirect_uri = encodeURIComponent(
        // "http://localhost:8080/lador/#/"
        " https://trade.healthplatform.xyz/#/"
      );
      let url = window.location.href;
      if (url.indexOf("code") == -1) {
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx70deea58f0c566b2&redirect_uri=" +
          redirect_uri +
          "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
      }
      console.log(url, "url", this.queryString);
      let codeUrl = JSON.parse(this.queryString(url));
      let code = codeUrl.code;
      console.log(code);
      this.getUserInfo(code);
    },
    queryString(url) {
      let arr = []; //存储参数的数组
      let res = {}; //存储最终JSON结果对象
      arr = url.split("?")[1].split("&");
      for (let i = 0, len = arr.length; i < len; i++) {
        //如果有等号，则执行赋值操作
        if (arr[i].indexOf("=") != -1) {
          let str = arr[i].split("="); //str=[a,1];
          res[str[0]] = str[1];
        } else {
          //没有等号，则赋予空值
          res[arr[i]] = "";
        }
      }
      res = JSON.stringify(res); //转化为JSON字符串
      return res;
    },
    //获取用户信息
    async getUserInfo(code) {
      let data = {
        code: code
      };
      getCode_api(data).then(res => {
        console.log(res, 123123123);
        if (res.code == 0) {
          // this.datum[0]=res;
          console.log(1);
          Cookies.set("token", res.data.access_token);
          this.applyUser();
          // locache.set('milk_seller_info',res,604800);
        } else {
          // this.datum[0] = locache.get('milk_seller_info')
        }
      });
    },
    propShow(message) {
      this.toastShow = true;
      this.configData.title = message;
      setTimeout(() => {
        this.toastShow = false;
      }, 1000);
    },
    async loginBtn() {
      let param = {
        mobile: this.captchaConfig.mobile,
        code: this.captchaConfig.code
      };
      let res = await apiCommon.setjudge(param);
      if (res.code == 0) {
        if(this.queryList.type==1){
          this.$router.push("../index");
        }else{
          this.$router.push("./pattern");
        }
        
      } else {
        this.toastShow = true;
        this.configData.title = res.error;
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
      }
    },

    async applyUser() {
      let res = await apiCommon.getUserInfo_api();
      console.log(res, "res");
      if (res.code == 0) {
        if (res.data.activate) {
          console.log(1)
        }else{
           if (
            res.data.user.member_id==0
          ) {
            console.log(1)
          }else{
            if (
            res.data.user.union_member == 0 ||
            res.data.user.union_shareholder == 0
          ) {
            if(this.queryList.type==1){
          this.$router.push("../index");
        }else{
          this.$router.push("./pattern");
        }
            return false;
          }

          if(res.data.user.union_shareholder == 0){
            this.$router.push("../index")
          }
          }
        }
      }
    }
  }
    }
</script>
<style scoped type="text/css">
    .contaners_share{
        width: 100%;
        height:100vh;
        position: relative;
        font-size: 25px;
        color: #DEB77A;
    }
    .content_share{
        position: absolute;
        top: 0;left: 46px;
        width:666px;
        margin: 0 100px 0;
        letter-spacing:10px
    }
    .messageTitle13 .messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle13 {
  text-align: right;
  margin-right: 20px;
  font-size: 20px;
  color: #fff;
  position: absolute;
  top: 20px;right: 10px;
  z-index: 3
}

.content_activate{
    width:630px;
    position: absolute;
    top: 0;left: 50%;
    transform: translateX(-50%)
}

.activate_text{
    font-size: 25px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    /* text-align:center; */
    margin-top: 111px;
    margin-bottom: 228px;
    line-height:40px
}
.activate_list{
    display: flex;
}
.activate_title1{
    width:150px;
    line-height:50px;
    letter-spacing:5px
}
.inputSubmit_ac{
    background-color:transparent;
    border: 1px solid #DEB77A;
    width: 349px;
    height: 45px;
    margin-bottom: 48px;
    padding-left: 10px
    /* margin-right:20px */
}

.plass{
  
}

 .inputSubmit_ac::-webkit-input-placeholder{
          color: #DEB77A;
          font-size: 20px
}

.getCode{
    width: 100px;
    height: 28px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 28px;
    color: #DEB77A;
    opacity: 1;
    text-decoration:underline;
    padding-bottom: 5px;
    margin-left: 10px;
    margin-top: 10px
}
.activate_text3{
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    opacity: 1;
    margin: 60px auto 0;
    text-align: center;
}
.activateBtn{
    width:306px ;
    height:81px;
    background-color: #DEB77A;
    border-radius: 40px;
    text-align:center;
    line-height:81px;
    color:#fff;
    font-size: 30px;
    margin: 58px auto 72px;
}
.ac_history{
    font-size: 25px;color:#DEB77A;
    text-align:center
}
</style>