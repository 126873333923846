// const TencentID = /dev/.test(process.env.NODE_ENV) ? '2034218683' : '2006574121';
// import TencentCaptcha from 'vue-tencent-captcha';
const TencentID = '2062722081';//2062722081


function getCaptcha(){
  console.log(3,window)
  return new Promise(resolve => {
    if(window.TencentCaptcha){
      console.log(2)
      let o = new TencentCaptcha(TencentID, cb);
      o.show();
    }

    function cb(res){
      if(res.ret === 2) return resolve(null);

      resolve(res);
    }
  });
}



export default {
  getCaptcha,
}